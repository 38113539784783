import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import NumberFormat from 'react-number-format';
import Modal from '../components/Modal';

const PersonalInformation = ({
    availableCount,
    personalInfo,
    saveInfo,
    nameError
}) => {
    const PersonalInfoSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone: Yup.string(),
        reservations: Yup.number()
            .min(1, 'You must reserve at least one seat to continue.')
            .max(
                availableCount,
                `You cannot reserve more than ${availableCount} seats.`
            )
            .required('Required')
    });

    return (
        <Modal isOpen={true}>
            <Formik
                initialValues={personalInfo}
                enableReinitialize='true'
                validationSchema={PersonalInfoSchema}
                onSubmit={(values) => {
                    saveInfo(values);
                }}
                validateOnChange
            >
                {({ values, errors, touched, handleSubmit, handleChange }) => (
                    <form className='form' onSubmit={handleSubmit} noValidate>
                        <div className='form-section'>
                            <label htmlFor='name'>Name</label>
                            <input
                                id='name'
                                name='name'
                                type='text'
                                className={
                                    errors.name && touched.name
                                        ? 'form-control error'
                                        : 'form-control'
                                }
                                value={values.name}
                                onChange={handleChange}
                            />
                            {errors.name && touched.name && (
                                <div className='help-block'>{errors.name}</div>
                            )}
                        </div>
                        <div className='form-section'>
                            <label htmlFor='email'>Email</label>
                            <input
                                id='email'
                                name='email'
                                type='email'
                                className={
                                    errors.email && touched.email
                                        ? 'form-control error'
                                        : 'form-control'
                                }
                                value={values.email}
                                onChange={handleChange}
                            />
                            {errors.email && touched.email && (
                                <div className='help-block'>{errors.email}</div>
                            )}
                        </div>
                        <div className='form-section'>
                            <label htmlFor='phone'>Phone Number</label>
                            <NumberFormat
                                format='(###) ###-####'
                                id='phone'
                                name='phone'
                                type='text'
                                className={
                                    errors.phone && touched.phone
                                        ? 'form-control error'
                                        : 'form-control'
                                }
                                value={values.phone}
                                onChange={handleChange}
                            />
                            {errors.phone && touched.phone && (
                                <div className='help-block'>{errors.phone}</div>
                            )}
                        </div>
                        <div className='form-section'>
                            <label htmlFor='reservations'>
                                How many seats are you reserving today?
                            </label>
                            <input
                                id='reservations'
                                name='reservations'
                                type='number'
                                className={
                                    errors.reservations && touched.reservations
                                        ? 'form-control error'
                                        : 'form-control'
                                }
                                value={values.reservations}
                                onChange={handleChange}
                            />
                            {errors.reservations && touched.reservations && (
                                <div className='help-block'>
                                    {errors.reservations}
                                </div>
                            )}
                        </div>
                        <button className='btn primary form-btn' type='submit'>
                            Save
                        </button>
                        {nameError !== '' && (
                            <div className='alert danger'>{nameError}</div>
                        )}
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default PersonalInformation;
