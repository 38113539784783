import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    projectId: process.env.REACT_APP_PROJECT_ID,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
});

// if (window.location.hostname === 'localhost') {
//     app.firestore().settings({
//         host: 'localhost:8080',
//         ssl: false
//     });
// }

export const writeDoc = async (collection, docId, record, merge = true) => {
    if (docId != null) {
        await app
            .firestore()
            .collection(collection)
            .doc(docId)
            .set(record, { merge: merge });
        return { id: docId };
    } else {
        const resp = await app.firestore().collection(collection).add(record);
        return resp;
    }
};

export const getDoc = async (col, docId) => {
    if (docId != null) {
        const resp = await app
            .firestore()
            .collection(col)
            .doc(docId)
            .get()
            .then((doc) => {
                return {
                    id: doc.id,
                    ...doc.data()
                };
            });
        return resp;
    }
};

export const queryDoc = async (col, wheres) => {
    let collection = await app.firestore().collection(col);

    wheres.forEach((where) => {
        collection = collection.where(where.field, where.operator, where.value);
    });

    const resp = await collection
        .get()
        .then((snapshot) => {
            return {
                id: snapshot.docs[0].id,
                ...snapshot.docs[0].data()
            };
        })
        .catch((error) => {
            console.log(error);
            return {};
        });

    return resp;
};

export const deleteDoc = async (collection, docId) => {
    if (docId != null) {
        const resp = await app
            .firestore()
            .collection(collection)
            .doc(docId)
            .delete();
        return resp;
    } else {
        return null;
    }
};

export default app;
