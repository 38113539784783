import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ChurchContext from '../contexts/ChurchContext';

export default function ChurchInfo() {
    const { church, service } = useContext(ChurchContext);

    return (
        <div className="churchInfo" id="churchInfo">
            <div className="label">
                {church && church.image ? 
                <Link to={`/`} >
                    <img className="church-logo" src={church.image} alt="Church Logo"/>
                </Link>
                : null }
                <div>
                    {church && <div className="church-name">{church.name}</div>}
                    {!service && <div className="service-name">Upcoming Services</div>}
                    {service && <div className="service-name">{service.name}</div>}
                </div>
            </div>

            {service && <div className="legend">
                <div className="item">
                    <div className="seat-border-notSelected">
                        <div className="seat unavailable"/>
                    </div>
                    Unavailable
                </div>
                <div className="item">
                    <div className="seat-border-notSelected"> 
                        <div className="seat available"/>
                    </div>
                    Available
                </div>
                <div className="item">
                    <div className="seat-border-notSelected">
                        <div className="seat booked"/>
                    </div>
                    Booked
                </div>
                <div className="item">
                    <div className="seat-border-selected">
                        <div className="seat selected"/>
                    </div>
                    Selected
                </div>
            </div>}
        </div> 
    )
}