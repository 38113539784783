import React from 'react';
import Modal from '../components/Modal';

const CompletedReservation = ({ churchId }) => {
    return (
        <Modal isOpen={true}>
            <h4 className='modal-section center'>You're all done here!</h4>
            <div className='modal-section'>
                You will receive an email confirmation shortly.
            </div>
            <div className='modal-section'>(You can close this window now)</div>
            <div className='form-buttons'>
                &nbsp;
                <a
                    className='btn outline'
                    href={`https://${churchId}.churchreserve.com`}
                >
                    View Other Services
                </a>
            </div>
        </Modal>
    );
};

export default CompletedReservation;
