import React, { useState, useEffect } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import app from './base';
import GuestApp from './screens/GuestApp/GuestApp';
import './App.scss';
import ChurchContext from './contexts/ChurchContext';
import Loading from './modals/Loading';

const App = () => {
    const [subdomain, setSubDomain] = useState(
        window.location.host.split('.')[0]
    );

    const [church, setChurch] = useState();
    const [service, setService] = useState();
    const [reservations, setReservations] = useState();

    const [personalInfo, setPersonalInfo] = useState({});
    const [showEdit, setShowEdit] = useState();
    const [editClicked, setEditClicked] = useState(false);
    const [uxLoading, setUxLoading] = useState(true);
    const [togglePersonalInfo, setTogglePersonalInfo] = useState(false);

    const [churchRec, churchLoading, churchError] = useDocument(
        app.firestore().doc(`churches/${subdomain}`)
    );

    const churchDoc = !churchLoading && !churchError && churchRec;

    useEffect(() => {
        setChurch(churchDoc && { id: churchDoc.id, ...churchDoc.data() });
    }, [churchDoc]);

    useEffect(() => {
        // *** Testing override
        const testingDomain = new URLSearchParams(window.location.search).get(
            'testingChurchDomain'
        );
        if (testingDomain) {
            setSubDomain(testingDomain);
        }
    }, []);

    const context = {
        subdomain,
        church,
        service,
        personalInfo,
        editClicked,
        showEdit,
        reservations,
        togglePersonalInfo,
        setService,
        setPersonalInfo,
        setShowEdit,
        setEditClicked,
        setTogglePersonalInfo,
        setReservations
    };

    setTimeout(() => {
        setUxLoading(false);
    }, 1000);

    return (
        <>
            <ChurchContext.Provider value={context}>
                <Loading toggle={uxLoading} />
                <GuestApp />
            </ChurchContext.Provider>
        </>
    );
};

export default App;
