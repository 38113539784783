//react & hooks
import React, { useState, useContext, useEffect, createContext } from 'react';

//react functions
import ServiceMap from '../../components/ServiceMap';
import ChurchContext from '../../contexts/ChurchContext';

//router
import { useParams } from 'react-router-dom';

//helper node modules
import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import app, { writeDoc, queryDoc } from '../../base';

import cookie from 'react-cookies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import PersonalInformation from '../../modals/PersonalInformation';
import ReservationConfirm from '../../modals/ReservationConfirm';
import CompletedReservation from '../../modals/CompletedReservation';
import FullService from '../../modals/FullService';
import SeatingCollision from '../../modals/SeatingCollision';

export const SeatsContext = createContext({});

const ServiceView = () => {
    const { id } = useParams();
    const {
        church,
        personalInfo,
        service,
        reservations,
        setService,
        setReservations,
        setShowEdit,
        setPersonalInfo,
        togglePersonalInfo,
        setTogglePersonalInfo
    } = useContext(ChurchContext);
    const [process, setProcess] = useState(false);
    const [availableCount, setAvailableCount] = useState(0);
    const [firstConfirm, setFirstConfirm] = useState(false);
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [seats, setSeats] = useState({});
    const [isNew, setIsNew] = useState(false);
    const [nameError, setNameError] = useState('');

    const [servRec, servLoading, servError] = useDocument(
        app.firestore().doc(`services/${id}`)
    );
    const [resRec, resLoading, resError] = useCollection(
        app.firestore().collection('reservations').where('serviceId', '==', id)
    );

    const serviceDoc = !servLoading && !servError && servRec;
    const reservationsCol = !resLoading && !resError && resRec;

    const totalSeats = (list, status) => {
        if (!list || list.length === 0) {
            return 0;
        }

        return list.reduce((accum, item) => {
            if (item.status === status) {
                return accum + item.seats.length;
            }

            return accum + 0;
        }, 0);
    };
    const totalRequests = (list) => {
        if (!list || list.length === 0) {
            return 0;
        }

        // explicity check for validity to avoid any errors in the count
        const temp = list.filter(
            (item) => item.requestedSeats && item.requestedSeats > 0
        );
        return temp.reduce((accum, item) => accum + item.requestedSeats, 0);
    };

    //listen for firebase changes
    useEffect(() => {
        setService(serviceDoc && { id: serviceDoc.id, ...serviceDoc.data() });

        let reservationsDocs = [];
        reservationsCol &&
            reservationsCol.docs.forEach((doc) => {
                reservationsDocs.push({ id: doc.id, ...doc.data() });
            });

        setReservations(reservationsDocs);
        setShowEdit(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceDoc, reservationsCol]);

    // calc for available seats
    useEffect(() => {
        if (service && service.reservationType) {
            console.log('service', service);

            const totalCapacity =
                service.socialDistancing.capacityType === 'number'
                    ? service.socialDistancing.capacity
                    : Math.round(
                          (seats.available + seats.blockout + seats.reserved) *
                              (service.socialDistancing.capacity / 100)
                      );

            if (totalCapacity === 0) {
                return;
            }

            console.log('total requests', totalRequests(reservations));

            if (service.reservationType === 4) {
                setAvailableCount(totalCapacity - totalRequests(reservations));
                return;
            }

            const capAndAvailable =
                totalCapacity - totalSeats(reservations, 'reserved');

            if (seats.avalable < capAndAvailable - seats.blockout) {
                setAvailableCount(seats.available);
            } else if (capAndAvailable > seats.available) {
                setAvailableCount(seats.available);
            } else {
                setAvailableCount(capAndAvailable);
            }
        }
    }, [seats, reservations]);

    // capacity limit checker
    useEffect(() => {
        if (availableCount <= 0 && process !== false) {
            setProcess((p) => ({
                ...p,
                current: 'serviceFull'
            }));
        } else if (availableCount > 0 && process !== false) {
            setProcess((p) => ({
                ...p,
                current: p[p.currentKey]
            }));
        }
    }, [seats, availableCount]);

    // process setter
    useEffect(() => {
        if (!service || process !== false) {
            return;
        }

        if (service.reservationType === 1 || service.reservationType === 4) {
            setProcess({
                current: 'personalModal',
                currentKey: 1,
                1: 'personalModal',
                2: 'confirmModal',
                3: 'completedModal'
            });
        } else {
            setProcess({
                current: 'personalModal',
                currentKey: 1,
                1: 'personalModal',
                2: 'showMap',
                3: 'confirmModal',
                4: 'completedModal'
            });
        }
    }, [service]);

    // override for showing personal info form (from header)
    useEffect(() => {
        if (togglePersonalInfo === true) {
            setProcess((p) => ({
                ...p,
                currentKey: 1,
                current: 'personalModal'
            }));
        }
    }, [togglePersonalInfo]);

    const addSeat = (seats) => {
        if (seats.length !== selectedSeats.length) {
            setSelectedSeats(seats);
        }

        if (personalInfo.reservations <= 0) {
            return;
        }
        if (seats.length >= personalInfo.reservations && !firstConfirm) {
            setFirstConfirm(true);
            setProcess({
                ...process,
                currentKey: process.currentKey + 1,
                current: process[process.currentKey + 1]
            });
        }
    };

    const removeSeat = (index) => {
        console.log('remove seat');
        let tempSeats = [...selectedSeats];
        tempSeats.splice(index, 1);
        setSelectedSeats(tempSeats);
    };

    const getStatus = (resType) => {
        switch (resType) {
            case 1:
            case 2:
                return 'requested';
            case 3:
            case 4:
                return 'reserved';
            default:
                return 'reserved';
        }
    };

    const collisionDetection = () => {
        if (
            reservations &&
            reservations.length > 0 &&
            selectedSeats.length > 0
        ) {
            for (let res of reservations) {
                if (!res.seats || res.seats.length === 0) {
                    continue;
                }

                for (let seat of res.seats) {
                    const matching = selectedSeats.filter((selected) => {
                        return (
                            selected.level === seat.level &&
                            selected.section === seat.section &&
                            selected.row === seat.row &&
                            selected.seat === seat.seat
                        );
                    });

                    if (matching.length > 0) {
                        return 'detected';
                    }
                }
            }
        }
    };

    const writeReservations = async () => {
        if (collisionDetection() === 'detected') {
            return false;
        }

        const resDoc = {
            churchId: service.churchId,
            serviceId: service.id,
            guest: {
                name: personalInfo.name,
                email: personalInfo.email,
                phone: personalInfo.phone
            },
            status: getStatus(service.reservationType),
            seats: selectedSeats,
            createdDate: new Date().getTime(),
            firstTime: isNew
        };
        if (service.reservationType === 1 || service.reservationType === 4) {
            resDoc.requestedSeats = personalInfo.reservations;
        }

        writeDoc('reservations', null, resDoc).then(async (doc) => {
            cookie.save('crInfo', {
                name: personalInfo.name,
                email: personalInfo.email,
                phone: personalInfo.phone
            });
        });
        return true;
    };

    const toggleConfirm = async (forward) => {
        if (forward) {
            const written = await writeReservations();
            if (written) {
                setProcess({
                    ...process,
                    currentKey: process.currentKey + 1,
                    current: process[process.currentKey + 1]
                });
            } else {
                setProcess({
                    ...process,
                    current: 'seatingCollision'
                });
            }
        } else {
            setProcess({
                ...process,
                currentKey: process.currentKey - 1,
                current: process[process.currentKey - 1]
            });
        }
    };

    const backToSeatSelection = () => {
        setSelectedSeats([]);
        setProcess({
            ...process,
            currentKey: process.currentKey - 1,
            current: process[process.currentKey - 1]
        });
    };

    const savePersonalInfo = (values) => {
        if (validateName(values.name)) {
            setTogglePersonalInfo(false);
            setPersonalInfo(values);
            writeUser(values);
            setNameError('');
            setProcess({
                ...process,
                currentKey: process.currentKey + 1,
                current: process[process.currentKey + 1]
            });
        } else {
            setNameError('Please enter your first and last name.');
        }
    };

    const writeUser = async (values) => {
        //try to find existing user
        const existingUser = await queryDoc('users', [
            {
                field: 'email',
                operator: '==',
                value: personalInfo.email || values.email.toLowerCase().trim()
            }
        ]);
        // console.log(existingUser);
        if (!existingUser.id) {
            setIsNew(true);
        }

        const getChurches = () => {
            if (existingUser.churches) {
                const alreadyVisited = existingUser.churches.includes(
                    church.id
                );
                // console.log(alreadyVisited);
                if (alreadyVisited) {
                    return existingUser.churches;
                } else {
                    setIsNew(true);
                    return [...existingUser.churches, church.id];
                }
            } else {
                return [church.id];
            }
        };

        try {
            writeDoc('users', existingUser.id, {
                name: values.name.trim(),
                email: values.email.toLowerCase().trim(),
                phone: values.phone,
                churches: getChurches()
            });
        } catch (err) {
            console.log(err);
        }
    };

    const validateName = (nameTxt) => {
        return nameTxt.trim().split(' ').length > 1;
    };

    return (
        <SeatsContext.Provider
            value={{
                seats,
                setSeats: (obj) => setSeats(obj)
            }}
        >
            {process.current === 'seatingCollision' && (
                <SeatingCollision backToSelection={backToSeatSelection} />
            )}
            {process.current === 'personalModal' && (
                <PersonalInformation
                    availableCount={availableCount}
                    personalInfo={personalInfo}
                    saveInfo={savePersonalInfo}
                    nameError={nameError}
                />
            )}
            {process.current === 'confirmModal' && (
                <ReservationConfirm
                    serviceState={service}
                    personalInfo={personalInfo}
                    selectedSeats={selectedSeats}
                    confirm={toggleConfirm}
                />
            )}
            {process.current === 'completedModal' && (
                <CompletedReservation churchId={service.churchId} />
            )}
            {process.current === 'serviceFull' && (
                <FullService churchId={service.churchId} />
            )}
            <div className='seatSelection' id='seatSelection'>
                <div className='item title'>Your Seating</div>
                {service && service.reservationType !== 1 && (
                    <div className='seats'>
                        {selectedSeats.length === 0 && (
                            <div className='item message'>
                                You haven't selected any seats yet!
                            </div>
                        )}
                        {selectedSeats
                            .sort((a, b) => {
                                let rowA = a.row;
                                let rowB = b.row;
                                if (rowA === rowB) {
                                    let numA = a.seat;
                                    let numB = b.seat;
                                    return numA > numB;
                                }
                                return rowA > rowB;
                            })
                            .map((seat, index) => {
                                return (
                                    <button
                                        className='item btn accent'
                                        onClick={() => removeSeat(index)}
                                        key={index}
                                    >
                                        <div>
                                            {service.map.includeSection
                                                ? seat.view.section + '-'
                                                : ''}
                                            {seat.view.row}-{seat.view.number}
                                        </div>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                );
                            })}
                    </div>
                )}

                <div className='save'>
                    <button
                        className='item btn primary'
                        onClick={() =>
                            setProcess({
                                ...process,
                                currentKey: process.currentKey + 1,
                                current: process[process.currentKey + 1]
                            })
                        }
                        disabled={
                            service &&
                            service.reservationType !== 1 &&
                            selectedSeats.length === 0
                        }
                    >
                        <FontAwesomeIcon icon={faCheckCircle} />
                        {service && service.reservationType === 1 ? (
                            <div className='completeText'>I Want In!</div>
                        ) : (
                            <div className='completeText'>Complete</div>
                        )}
                    </button>
                </div>
            </div>
            <div className='border'></div>
            <div
                className={`service-reservations ${
                    service && service.reservationType === 1 ? 'hidden' : ''
                }`}
            >
                {service && (
                    <ServiceMap
                        reservations={reservations}
                        resSelected={(seats) => addSeat(seats)}
                        selections={selectedSeats}
                        mapUrl={service.map && service.map.url}
                    />
                )}
            </div>
        </SeatsContext.Provider>
    );
};

export default ServiceView;
