import React, { useState, useEffect } from 'react';
import logo from '../img/centered-logo-white.png';

const Loading = ({ toggle }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(toggle);
    }, [toggle]);

    return (
        <div className={`loading-screen ${show === false ? 'hide' : ''}`}>
            <img src={logo} alt='' />
        </div>
    );
};

export default Loading;
