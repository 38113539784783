import React from 'react';
import Modal from '../components/Modal';

const FullService = ({ churchId }) => {
    return (
        <Modal isOpen={true}>
            <h4 className='center'>No seats available!</h4>
            <div className='modal-section center'>
                This service is full and has met it's capacity limit.
            </div>
            <div className='form-buttons'>
                <a
                    className='btn outline'
                    href={`https://${churchId}.churchreserve.com`}
                >
                    View Other Services
                </a>
            </div>
        </Modal>
    );
};

export default FullService;
