import React, { useState, useContext, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import ServiceCard from '../../components/serviceCard';
import ChurchContext from '../../contexts/ChurchContext';
import app from '../../base';

const ServiceList = () => {
    const { subdomain } = useContext(ChurchContext);
    const [services, setServices] = useState([]);
    const [preventShow, setPreventShow] = useState(true);
    const [today] = useState(Date.now());

    const [servRec, servLoading, servError] = useCollection(
        app
            .firestore()
            .collection('services')
            .where('churchId', '==', subdomain)
            .where('date', '>=', today)
            .where('status', '==', 'published')
            .orderBy('date')
    );

    const serviceDocs = !servLoading && !servError && servRec;

    useEffect(() => {
        let service = [];
        serviceDocs &&
            serviceDocs.docs.forEach((doc) => {
                service.push({
                    id: doc.id,
                    name: doc.data().name,
                    date: doc.data().dateStr.date,
                    time: doc.data().dateStr.time
                });
            });
        setServices(service);
    }, [serviceDocs]);

    // wait a moment to show the empty list text
    setTimeout(() => {
        setPreventShow(false);
    }, 500);

    return (
        <div className='services-list'>
            {services.length === 0 && !preventShow ? (
                <h5>
                    There are no upcoming services for this church. Check back
                    again later!
                </h5>
            ) : (
                services.map((item, i) => (
                    <ServiceCard service={item} key={i} />
                ))
            )}
        </div>
    );
};

export default ServiceList;
