import React, { useEffect, useState } from 'react';
import Modal from '../components/Modal';

const ReservationConfirm = ({
    serviceState,
    personalInfo,
    selectedSeats,
    confirm
}) => {
    const [service, setService] = useState({});

    useEffect(() => {
        setService(serviceState);
    }, [serviceState]);

    return (
        <Modal isOpen={true}>
            <h4 className='modal-section center'>Review Your Selection</h4>
            {service.reservationType === 2 || service.reservationType === 3 ? (
                <div className='modal-section center'>
                    <div>The seats you selected are: </div>
                    <div className='seat-list'>
                        {selectedSeats.map((s, i) => (
                            <div key={i}>
                                {service.map.includeSection
                                    ? s.view.section + '-'
                                    : ''}
                                {s.view.row}-{s.view.number}
                                {i !== selectedSeats.length - 1 && (
                                    <span>,&nbsp;</span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className='modal-section center'>
                    <p>
                        Once you are finished making your selection, submit
                        below.
                    </p>
                    <br />
                    <div>
                        <strong>
                            You are about to reserve {personalInfo.reservations}
                            {personalInfo.reservations === 1
                                ? ' seat'
                                : ' seats'}
                        </strong>
                    </div>
                </div>
            )}
            <div className='form-buttons'>
                <button className='btn primary' onClick={() => confirm(true)}>
                    <span>
                        {service.reservationType !== 1
                            ? 'Reserve'
                            : 'Submit Request'}
                    </span>
                </button>
                <button className='btn outline' onClick={() => confirm(false)}>
                    {service && service.reservationType !== 1
                        ? 'Cancel'
                        : 'Change Reservations'}
                </button>
            </div>
        </Modal>
    );
};

export default ReservationConfirm;
