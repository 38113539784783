import React, { useEffect, useContext } from 'react';
import cookie from 'react-cookies';

import ChurchInfo from '../../components/ChurchInfo';
import ServiceView from '../ServiceView/ServiceView';
import ServicesList from '../ServicesList/ServicesList';
import ChurchContext from '../../contexts/ChurchContext';
import crLogo from '../../img/horiz-logo-white.png';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from '../../components/footer';

export default function GuestApp() {
    const {
        showEdit,
        setPersonalInfo,
        setEditClicked,
        setTogglePersonalInfo
    } = useContext(ChurchContext);

    useEffect(() => {
        if (cookie.load('crInfo')) {
            setPersonalInfo({
                name: cookie.load('crInfo').name.trim() || '',
                email: cookie.load('crInfo').email.toLowerCase().trim() || '',
                phone: cookie.load('crInfo').phone || '',
                reservations: ''
            });
        }
    }, []);

    const edit = () => {
        setEditClicked(true);
        setTogglePersonalInfo(true);
    };

    return (
        <>
            <div className='guestApp' id='guestApp'>
                <Router>
                    <div className='header'>
                        <header>
                            <div className='logo-text'>
                                <img
                                    src={crLogo}
                                    className='top-logo'
                                    alt='churchreserve logo'
                                />
                            </div>
                            {showEdit && (
                                <button
                                    className='btn outline personal-info'
                                    onClick={edit}
                                >
                                    Edit Personal Info
                                </button>
                            )}
                        </header>
                        <ChurchInfo />
                    </div>
                    <Switch>
                        <Route path='/:id'>
                            <ServiceView />
                        </Route>
                        <Route path='/'>
                            <ServicesList />
                        </Route>
                    </Switch>
                </Router>
            </div>
            <Footer />
        </>
    );
}
