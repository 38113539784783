import React from 'react';
import Modal from '../components/Modal';

const SeatingCollision = ({ backToSelection }) => {
    return (
        <Modal isOpen={true}>
            <h4 className='modal-section center'>
                Oh no! Your seats have been taken.
            </h4>
            <div className='modal-section'>
                <div className='alert danger'>
                    One or more of your requested seats have been reserved by
                    someone else. Please select new seats.
                </div>
            </div>
            <div className='form-buttons'>
                <button
                    className='btn primary form-btn'
                    onClick={backToSelection}
                >
                    Select New Seats
                </button>
            </div>
        </Modal>
    );
};

export default SeatingCollision;
