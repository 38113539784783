import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div>&copy; Copright 2020</div>
            <div>|</div>
            <div>ChurchReserve</div>
            <div>|</div>
            <div>All Rights Reserved</div>
        </footer>
    );
};

export default Footer;
