import React from 'react';
import { Link } from 'react-router-dom';

export default function ServiceCard({service}) {
    return (
        <div className="service-card card padding-15-vert">
            <Link to={`/${service.id}`} className="top">
                <div className="name">
                    <div className="value">{service.name}</div>
                </div>
                <div className="datetime">
                    <div className="date">
                        <div className="label">Date</div>
                        <div className="value">{service.date}</div>
                    </div>
                    <div className="time">
                        <div className="label">Time</div>
                        <div className="value">{service.time}</div>
                    </div>
                </div>
            </Link>
        </div>
    )
}