import React, { useContext } from 'react';
import { SeatMap } from '@churchreserve/cavea';
import '@churchreserve/cavea/dist/index.css';
import useTextFile from '../hooks/useTextFile';
import { SeatsContext } from '../screens/ServiceView/ServiceView';

export default function ServiceMap({
    reservations,
    selections,
    resSelected,
    mapUrl
}) {
    const xmlText = useTextFile(mapUrl);
    const { setSeats } = useContext(SeatsContext);

    const handleSelection = (seats) => {
        resSelected(seats);
        console.log(seats);
    };

    return (
        <div className='serviceMap'>
            {mapUrl && (
                <SeatMap
                    reservations={reservations}
                    onSelect={handleSelection}
                    mapTemplate={xmlText}
                    selections={selections}
                    openSeats={setSeats}
                />
            )}
        </div>
    );
}
